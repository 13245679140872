import React, {Fragment} from 'react';
import Fade from 'react-reveal/Fade';
import subBg from '../../img/sub/product_bg.jpg';
import hexagon_pattern from '../../img/sub/hexagon_pattern1.png';

const PolicyManager = ({}) => {

    return (
     <Fragment>
        <div className="sub_visual" style={{backgroundImage: `url(${subBg})`}}>
            <h1>Policy Manager</h1>
        </div>

        <div className="product_bg jarallax" style={{backgroundImage: `url(${hexagon_pattern})`}}>
            <div className="wrap_1400">
                <h2>Major Functions</h2>
                <p>FRIIM Policy Manager는 보안 관리자가 보안정책의 이력을 관리하고 정책의 일관성을 유지할 수 있도록 합니다.</p>
                
                <div className="majorFunctions">
                    <ul>
                        <Fade bottom delay={100} distance="30px"><li>Batch Policy Management</li></Fade>
                        <Fade bottom delay={300} distance="30px"><li>Data Collector</li></Fade>
                        <Fade bottom delay={500} distance="30px"><li>History <br/>Management and<br/> Restorantion</li></Fade>
                        <Fade bottom delay={700} distance="30px"><li>Authetication Management</li></Fade>
                        <Fade bottom delay={900} distance="30px"><li>Security Analysis</li></Fade>
                        <Fade bottom delay={1100} distance="30px"><li>Provider<br/> Security<br/> Management</li></Fade>
                    </ul>
                </div>
            </div>
        </div>

        <div className="sub_section2 policyManager section-padding-50">
            <div className="wrap_1400">
                <section>
                    <h5>1.Maximize efficiency</h5>
                    <p>FRIIM Policy Manager는 보안 관리자가 보안정책의
                    이력을 관리하고 정책의 일관성을 유지할 수 있도록 합니다.</p>
                </section>
                <section>
                    <h5>2. Consistent security policy management</h5>
                    <p>사용자는 보안 정책 중복을 제거하고 멀티 클라우드 시스템에서 
                    잘못된 구성을 확인할 수 있습니다.  또한 이를 통해 일관된 보안 정책을 관리할 수 있습니다.</p>
                </section>
                <section>
                    <h5>3. System stability</h5>
                    <p>사전 정책 영향 분석을 통하여 정책 적용시 발생할 수 있는 문제를 사전에 파악하여 안정적인 클라우드 시스템 운영을 가능하게 합니다.</p>
                </section>
            </div>
        </div>

        <div className="sub_section3_monitor section-padding-100 wrap_1400">
            <div>
                <img src={require('../../img/sub/product_1_friim_1.png').default} alt=""/>
                <div className="section-padding-50">
                    <div className="monitor_li">
                        <span>1</span><p>각각의 프로바이더 인스턴스에 대하여 개별적인 보안정책을 설정해야 하는 불편함을  해소하기 위하여, 기 설정된 보안 정책의 일괄 적용이 가능하도록 하였습니다.</p>
                    </div>
                    <div className="monitor_li">
                        <span>2</span><p>보안 정책을 다중으로 생성 및 적용할 수 있으며,사용자는 워크로드의 목적에 맞추어 유동적인 정책을 적용할 수 있습니다.</p>
                    </div>
                </div>
            </div>
            <div>
                <img src={require('../../img/sub/product_1_friim_2.png').default} alt=""/>
                <div className="section-padding-50">
                    <div className="monitor_li">
                        <span>1</span><p>왼쪽에 보안정책을 적용할 리소스를 확인 할 수 있습니다.</p>
                    </div>
                    <div className="monitor_li">
                        <span>2</span><p>1의 리소스에 적용된 정책은 오른쪽에서 확인할 수 있습니다.</p>
                    </div>
                    
                </div>
            </div>
        </div>
    </Fragment>
    )
}

export default PolicyManager;
