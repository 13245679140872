import http from "../../http-common";


const getReleaseNotes = (page) => {
    return http.client
        .get(`/api/board/v1/releasenotes?isDeleted=false&size=5&page=${page}`)
        .then((response) => {
            return response.data;
        });
};

const getReleaseNote = (number) => {
    return http.client
        .get(`/api/board/v1/releasenotes/${number}`)
        .then((response) => {
            return response.data;
        });
};

const getSecurityReports = (page) => {
    return http.client
        .get(`/api/board/v1/securityreports?isDeleted=false&size=5&page=${page}`)
        .then((response) => {
            return response.data;
        });
};

const getSecurityReport = (number) => {
    return http.client
        .get(`/api/board/v1/securityreports/${number}`)
        .then((response) => {
            return response.data;
        });
};

const getVideos = () => {
    return http.client
        .get(`/api/board/v1/videos?isDeleted=false&isMain=true&sort=priority%2CASC`)
        .then((response) => {
            return response.data;
        });
};

const createContact = (parameter) => {
    return http.client
        .post(`/api/board/v1/contacts`, parameter)
        .then((response) => {
            return response.data;
        });
};


export default {
    createContact,
    getReleaseNote,
    getReleaseNotes,
    getSecurityReports,
    getSecurityReport,
    getVideos
};