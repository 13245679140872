import { SET_MESSAGE } from "../../constants/actions";
import { RELEASE_NOTE, RELEASE_NOTES, SECURITY_REPORT, SECURITY_REPORTS } from "../../constants/boardActions";
import boardService from "../../services/board/boardService";

export const getReleaseNote = (rowNumber) => (dispatch) => {
    return boardService.getReleaseNote(rowNumber).then(
        (response) => {
            dispatch({
                type: RELEASE_NOTE,
                payload: response,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

export const getReleaseNotes = (page) => (dispatch) => {
    return boardService.getReleaseNotes(page).then(
        (response) => {
            dispatch({
                type: RELEASE_NOTES,
                payload: response,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};
