// Commons
export const HEADER = "HEADER";
export const NAVIGATION = 'NAVIGAION';
export const NAVIGATION_OFF = 'NAVIGATION_OFF';
export const LOADING_ON = 'LOADING_ON';
export const LOADING_OFF = 'LOADING_OFF';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const DETAILPAGE_ON = 'DETAILPAGE_ON';
export const DETAILPAGE_OFF = 'DETAILPAGE_OFF';
export const ADDPAGE_ON = 'ADDPAGE_ON';
export const ADDPAGE_OFF ='ADDPAGE_OFF';
export const MODIFYPAGE_ON = 'MODIFYPAGE_ON';
export const MODIFYPAGE_OFF = 'MODIFYPAGE_OFF';
export const DISABLED = 'DISABLED';

// Login
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

// Message
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// Provider Types
export const PROVIDER_TYPES = "PROVIDER_TYPES";

