import React, {Fragment} from 'react';
import NotContent from '../NotContent';

const Pricing = ({}) => {

    return (
     <Fragment>        
         <div className="main-contents">
            <div className="not_content_wrap">
            <h1>현재 <span>요금 페이지 준비중</span> 입니다.</h1>
            <p>보다 나은 서비스 제공을 위하여 페이지 준비중에 있습니다.<br/>
                가격문의는 아래 메일로 문의해주세요.<br/><br/><span>sales@friim.com</span>
            </p>
            </div>
        </div>
    </Fragment>

    )
}

export default Pricing;
