import React, {Fragment, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { animateScroll as scroll, scroller } from 'react-scroll';
import Fade from 'react-reveal/Fade';
import subBg from '../../img/sub/aboutCloudeSecurity_bg.jpg';
import blackBg from '../../img/sub/friim_sub_bg_1.jpg';
import { NAVIGATION_OFF } from '../../constants/actions';

const CloudSecurity = ({location}) => {
    const dispatch = useDispatch();
    const { navigation } = useSelector(state => state.common);
    
    const casbRef = useRef();

    useEffect(() => {
        if(location.state !== undefined) {
            if(navigation) {
                dispatch({type:NAVIGATION_OFF});
            }
            var scrollId = location.state.scrollId;
            scroller.scrollTo(`${scrollId}`, {
                duration : 800,
                offset:-80,
                smooth:'easeInOutQuartz'
            });
        }
    },[location.state !== undefined ? location.state.scrollId : {}]);

    return (
     <Fragment>
        <div className="sub_visual" style={{backgroundImage : `url(${subBg})`}}>
            <h1>About Cloud Security</h1>
        </div>

        <div className="aboutCloudeSecurity" id="section_gartner">
            <div className="wrap_1400">
                <h2>Cloud Security Strategy Complete of Gartner</h2>
                <Fade bottom distance="50px"><img src={require('../../img/sub/aboutCloudeSecurity_1.jpg').default} alt=""/></Fade>
            </div>
        </div>

        <div className="aboutCloudeSecurity" id="section_cspm" style={{borderTop:"1px solid #e7e7e7"}}>
            <div className="wrap_1400">
                <h2>Cloud Security Posture Management</h2>

                <div className="mobile">
                <Fade bottom distance="50px"><img src={require("../../img/sub/aboutCloudeSecurity_2.png").default} alt=""/></Fade>
                </div>
                <div className="cloudManagement pc">
                    <ul>
                        <Fade bottom distance="50px" delay={100}><li className="text_right">SERICE REQUEST</li></Fade>
                        <Fade bottom distance="50px" delay={100}><li className="text_right">INVENTORY &amp; CLASSIFICATION </li></Fade>
                        <Fade bottom distance="50px" delay={100}><li className="text_right">MONITORING &amp; ANALYTICS </li></Fade>
                        <Fade bottom distance="50px" delay={100}><li className="text_right">PACKAGING &amp; DELIVERY </li></Fade>
                        <Fade bottom distance="50px" delay={100}><li className="text_left">PROVISIONING &amp; ORCHESTRATION</li></Fade>
                        <Fade bottom distance="50px" delay={100}><li className="text_left">COST MANAGEMENT &amp; RESOURCE OPIMIZATION</li></Fade>
                        <Fade bottom distance="50px" delay={100}><li className="text_left">CLOUD MIGRATION,BACKUP &amp; DR</li></Fade>
                        <Fade bottom distance="50px" delay={100}><li className="text_left">IDENTITY, SECURITY &amp; COMPLIANCE</li></Fade>
                    </ul>
                </div>
            </div>
        </div>

        <section className="cspm newsletter-area section-padding-100 bg-img jarallax" style={{backgroundImage : `url(${blackBg})`}}>
            <h2>What is CSPM?</h2>
            <p>CSPM이란 클라우드 보안 형상관리(Cloud Security Posture Management)로 클라우드 인프라상의 보안상의 위험을 지속적으로 관리하고 평가하는 솔루션을 말하며, CSPM은 크게 3가지를 지원해야 한다.</p>

            <Fade bottom delay={100} distance="50px">
                <div className="row_box">
                    <span>1</span>
                    <p>지속적으로 변경되는클라우드 인프라에서 네트워크 및 클라우드 서비스에 대한 취약성을 지속적으로 체크하고 대응할 수 있도록 지원해야 한다.</p>
                </div>
            </Fade>
            <Fade bottom delay={300} distance="50px">
                <div  className="row_box">
                    <span>2</span>
                    <p>하나의 시스템에서 멀티 클라우드 상의 리소스를 관리할 수 있도록 가시성을 제공해야 한다.</p>
                </div>
            </Fade>
            <Fade bottom delay={500} distance="50px">
                <div className="row_box">
                    <span>3</span>
                    <p>각각의 리소스가 준수하여야 하는 컴플라이언스의 위반 및 준수여부를 확인하고 신속한 대응이 가능하여야 한다.</p>
                </div>
            </Fade>
        </section>

        <div className="element aboutCloudeSecurity cloudPlatforms" id="section_cwpp">
            <div className="wrap_1400">
                <h2>Cloud workload Protection Platforms</h2>
                <img src={require('../../img/sub/aboutCloudeSecurity_3.png').default} alt=""/>
            </div>
        </div>

        <div className="aboutCloudeSecurity cwpp" style={{backgroundImage: `url(${blackBg})`}}>
            <div className="wrap_1400">
                <h2>What is CWPP?</h2>
                <p>CWPP는 멀티 클라우드 상의 워크로드에 대한 가시성 확보 및 공격 방어, 제어를 목적으로 하는 보안 솔루션이다.CWPP 솔루션은 아래와 같은 기능을 포함하여야 한다.</p>
                <div className="column_box_wrap">
                    <Fade bottom delay={100} distance="50px">
                    <div className="column_box">
                        <span>1</span>
                        <p>보안 강화 및 설정/취약점 관리</p>
                    </div>
                    </Fade>
                    <Fade bottom delay={300} distance="50px">
                    <div className="column_box">
                        <span>2</span>
                        <p>시스템 무결성 보장</p>
                    </div>
                    </Fade>
                    <Fade bottom delay={500} distance="50px">
                    <div className="column_box">
                        <span>3</span>
                        <p>익스플로잇 예방 및 메모리 보호</p>
                    </div>
                    </Fade>
                    <Fade bottom delay={700} distance="50px">
                    <div className="column_box">
                        <span>4</span>
                        <p>호스트 기반 침입 탐지 시스템</p>
                    </div>
                    </Fade>
                    <Fade bottom delay={900} distance="50px">
                    <div className="column_box">
                        <span>5</span>
                        <p>네트워크 방화벽,가시성 확보 및 마이크로 세그멘테이션</p>
                    </div>
                    </Fade>
                    <Fade bottom delay={1100} distance="50px">
                    <div className="column_box">
                        <span>6</span>
                        <p>애플리케이션 제어</p>
                    </div>
                    </Fade>
                    <Fade bottom delay={1300} distance="50px">
                    <div className="column_box">
                        <span>7</span>
                        <p>서버 워크로드 EDR, 행위 모니터링 및 위협 탐지, 대응</p>
                    </div>
                    </Fade>
                    <Fade bottom delay={1500} distance="50px">
                    <div className="column_box">
                        <span>8</span>
                        <p>안티 멀웨어</p>
                    </div>
                    </Fade>
                </div>
            </div>
        </div>

        <div className="aboutCloudeSecurity section-padding-100" id="section_casb">
            <h2>Cloud Access Security Broker</h2>
            <Fade bottom delay={100} distance="50px">
            <img src={require('../../img/sub/aboutCloudeSecurity_4.png').default} alt=""/>
            </Fade>
        </div>

        <div className="casb newsletter-area section-padding-100 bg-img jarallax" style={{backgroundImage: `url(${blackBg})`}} ref={casbRef}>
            <h2>What is CASB?</h2>
            <p>CASB는 단일 통제 포인트를 설정해, 인증, 접근 제어, 데이터 유출방지,로그 모니터링, 멀웨어 대응 등 클라우드 리소스에 대한 접근과 보안 정책을 적용하는 솔루션을 말하며, 다음과 같은 기능을 제공해야 한다.</p>
            <Fade bottom delay={100} distance="50px">
            <div className="row_box" data-delay="100ms">
                <span>1</span>
                <p>조직 전체의 클라우드 사용에 대한 가시성</p>
            </div>
            </Fade>
            <Fade bottom delay={300} distance="50px">
            <div className="row_box" data-delay="300ms">
                <span>2</span>
                <p>모든 규제 요건 준수를 보장하고 입증하는 방법</p>
            </div>
            </Fade>
            <Fade bottom delay={500} distance="50px">
            <div className="row_box" data-delay="500ms">
                <span>3</span>
                <p>클라우드에 데이터를 안전하게 저장하는 방법</p>
            </div>
            </Fade>
            <Fade bottom delay={700} distance="50px">
            <div className="row_box" data-delay="700ms">
                <span>4</span>
                <p>클라우드 사용에 따른 보안 위험을 수용할 수 있도록 보장하는 만족스러운 수준의 위협 보호</p>
            </div>
            </Fade>
        </div>

        <div className="productManual section-padding-50 cloudDocument" id="section_doc">
            <h2>CLOUD SECURITY DOCUMENT</h2>
            
            <div>
                <div>
                    <section>
                        <img src={require('../../img/sub/cloudDocument.jpg').default} alt=""/>
                    </section>
                    <section>
                        <h5>Technical Insights:</h5>
                        <p>Cloud Security Through the Looking Glass</p>
                        <span className="name">Patrick Hevesi</span>
                    </section>
                </div>

                <div>
                    <section>
                        <img src={require('../../img/sub/cloudDocument.jpg').default} alt=""/>
                    </section>
                    <section>
                        <h5>Technical Insights:</h5>
                        <p>Cloud Security Through the Looking Glass</p>
                        <span className="name">Patrick Hevesi</span>
                    </section>
                </div>

                <div>
                    <section>
                        <img src={require('../../img/sub/cloudDocument.jpg').default} alt=""/>
                    </section>
                    <section>
                        <h5>Technical Insights:</h5>
                        <p>Cloud Security Through the Looking Glass</p>
                        <span className="name">Patrick Hevesi</span>
                    </section>
                </div>

                <div>
                    <section>
                        <img src={require('../../img/sub/cloudDocument.jpg').default} alt=""/>
                    </section>
                    <section>
                        <h5>Technical Insights:</h5>
                        <p>Cloud Security Through the Looking Glass</p>
                        <span className="name">Patrick Hevesi</span>
                    </section>
                </div>
            </div>
        </div>
    </Fragment>

    )
}

export default CloudSecurity;
