import React, { useEffect, useState } from "react";
import { animateScroll as scroll, scroller } from 'react-scroll';

import './css/Login.css'

const MoveTop = () => {

    const [scrollPosition, setScrollPosition] = useState(0);

    const move = () => {
        scroll.scrollToTop();
    }

    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }

    useEffect(() => {
        window.addEventListener('scroll', updateScroll);
    });

    return (
        <div className={"top_btn_wrap " + (scrollPosition < 200 ? "top_btn_none" : "")}>
            <a onClick={() => move()}>
                <img src={require('../img/sub/page_arrow.svg').default}/>
            </a>
        </div>
    )
};


export default MoveTop;