import React, { useRef, useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";
import { animateScroll as scroll, scroller } from 'react-scroll';
import subBg from '../../img/sub/developmentHistory_bg.jpg';
import blackBg from '../../img/sub/friim_sub_bg_1.jpg';
import { createContact } from '../../actions/board/company';
import { contactusValidation } from '../Validation';
import { NAVIGATION_OFF } from '../../constants/actions';
import { values } from 'lodash';


const DevelopmentHistory = ({location}) => {

    const dispatch = useDispatch();
    const form = useRef();
    const checkButton = useRef();
    const { navigation } = useSelector(state => state.common);

    const initForm = {
        name : "",
        email:"",
        phoneNumber:"",
        company: "",
        agree:false
    };

    const [ formState, setFormState ] = useState(initForm);
    const [formErrors, setFormErrors] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onChange = (e) => {
        const { name,value } = e.target;
        var nextState = {
                ...formState,
                [name] : value
            };
        setFormState(nextState);
    };

    const checkedAgree = (e) => {
        const { name,checked } = e.target;
        var nextState = {
                ...formState,
                [name] : checked
            };
        setFormState(nextState);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(contactusValidation(formState));
        setIsSubmitting(true);
    };

    useEffect(() => {
        if(Object.keys(formErrors).length === 0 && isSubmitting) {
            form.current.validateAll();

            if(!formState.agree) {
                alert("개인정보수집에 동의해주십시오.");
            } else {
                const parameter = {
                    userName : formState.name,
                    userMobile : formState.phoneNumber,
                    userEmail : formState.email,
                    companyName : formState.company,
                    languageCode : 'ko'
                }
                if (checkButton.current.context._errors.length === 0) {
                    dispatch(createContact(parameter))
                        .then((result) => {
                            if(result) {
                                setFormState(initForm);
                                alert("메일을 전송했습니다.");
                            } else {
                                alert("메일전송에 실패했습니다.");
                            }
                        })
                        .catch(() => {
                            alert("메일전송에 실패했습니다.");
                        });
                } else {
                    alert("메일전송에 실패했습니다.");
                }
            }
        }
    },[formErrors]);

    useEffect(() => {;
        if(location.state !== undefined) {
            if(navigation) {
                dispatch({type:NAVIGATION_OFF});
            }
            var scrollId = location.state.scrollId;
            scroller.scrollTo(`${scrollId}`, {
                duration : 800,
                delay:0,
                offset:-80,
                smooth:'easeInOutQuartz'
            });
        }
    },[location.state !== undefined ? location.state.scrollId : {}]);

    return (
     <Fragment>
        <div className="sub_visual" style={{backgroundImage: `url(${subBg})`}}>
            <h1>Development History</h1>
        </div>
    
        <div className="history section-padding-100" id="section_history">
                <h2 >Development History</h2>
                <div className="history_box">
                    <div className="left_box">2021.4Q</div>
                    <div className="right_box">
                        <p><span>01</span>Workload 분석 방법 고도화</p>
                        <p><span>02</span>클라우드 보안 관제 자동화 고도화</p>
                    </div>
                </div>

                <div className="history_box">
                    <div className="left_box">2021.3Q</div>
                    <div className="right_box">
                        <p><span>01</span>내부 통제 기능 강화</p>
                        <p><span>02</span>클라우드 리소스 관리 방법론 고도화</p>
                    </div>
                </div>

                <div className="history_box">
                    <div className="left_box">2021.2Q</div>
                    <div className="right_box">
                        <p><span>01</span>자체 Compliance 규정 항목 고도화</p>
                        <p><span>02</span>네트워크 분석 범위 확대</p>
                    </div>
                </div>

                <div className="history_box">
                    <div className="left_box">2021.1Q</div>
                    <div className="right_box">
                        <p><span>01</span>FRIIM(클라우드 통합보안 솔루션) v2.0 출시</p>
                        <p><span>02</span>네트워크 기반 보안 분석 강화</p>
                        <p><span>03</span>Serverless 관리 및 분석 지원</p>
                    </div>
                </div>

                <div className="history_box">
                    <div className="left_box">2020.3Q</div>
                    <div className="right_box">
                        <p><span>01</span>FRIIM(클라우드 통합보안 솔루션) v1.0 출시</p>
                        <p><span>02</span>On-Premise(OpenStack, VMware) 지원 강화</p>
                        <p><span>03</span>컨테이너 관리 및 분석 지원</p>
                        <p><span>04</span>CSPM, CWPP 솔루션 영역 지원 강화</p>
                        <p><span>05</span>OCP(Oracle Cloud)지원</p>
                        <p><span>06</span>솔루션 구축 및 기술지원 시작</p>
                    </div>
            </div>

            <div className="history_box">
                <div className="left_box">2020.1Q</div>
                <div className="right_box">
                    <p><span>01</span>FRIIM(클라우드 통합보안 솔루션)베타버전 v0.7 출시</p>
                    <p><span>02</span>AWS,Azure,GCP,Naver Cloud 지원</p>
                    <p><span>03</span>Hybrid Cloud 환경 지원 고도화</p>
                </div>
            </div>

            <div className="history_box">
                <div className="left_box">2019.2Q</div>
                <div className="right_box">
                    <p><span>01</span>Multi &amp; Hybrid Cloud 통합보안 솔루션 FRIIM 프토로타입 개발</p>
                    <p><span>02</span>클라우드 환경 적용 후 보완 필요 사항 발굴</p>
                </div>
            </div>

            <div className="history_box">
                <div className="left_box">2019.1Q</div>
                <div className="right_box">
                    <p><span>01</span>클라우드 보안 위험성 보완을 위한 솔루션 기획</p>
                    <p><span>02</span>IaaS, PaaS, SaaS 전방위 보안 방법론 정립</p>
                </div>
            </div>
        </div>

        <div className="contactUs newsletter-area section-padding-100 bg-img jarallax" style={{backgroundImage: `url(${blackBg})`}} id="section_contact_us">
            <div className="contactUs_wrap">
                <div className="contactUs_left">
                    <h2>Contact Us</h2>
                    <address>서울특별시 구로구 디지털로26길 61, 1604호 (구로동, 에이스하이엔드타워2차)</address>
                    <p>전화번호: 02)-2039-8160</p>
                </div>
                <div className="formGroup contactUs_right">
                    <Form onSubmit={handleSubmit} ref={form} autocomplete="off">
                        <div className="input_row">
                            <label for="name">성명</label>
                            <Input
                                id="name"
                                onChange={onChange}
                                value={formState.name}
                                name="name"
                                type="text"/>
                            {formErrors.name && (
                                <div className="status-error">{formErrors.name}</div>
                            )}
                        </div>
                        <div className="input_row">
                            <label for="email">이메일</label>
                            <Input
                                id="email"
                                onChange={onChange}
                                value={formState.email}
                                name="email"
                                type="text"/>
                            {formErrors.email && (
                                <div className="status-error">{formErrors.email}</div>
                            )}
                        </div>
                        <div className="input_row">
                            <label for="phoneNumber">핸드폰</label>
                            <Input
                                id="phoneNumber"
                                onChange={onChange}
                                value={formState.phoneNumber}
                                name="phoneNumber"
                                type="tel"/>
                            {formErrors.phoneNumber && (
                                <div className="status-error">{formErrors.phoneNumber}</div>
                            )}
                        </div>
                        <div className="input_row">
                            <label for="company">회사명</label>
                            <Input
                                id="company"
                                onChange={onChange}
                                value={formState.company}
                                name="company"
                                type="text"/>
                            {formErrors.company && (
                                <div className="status-error">{formErrors.company}</div>
                            )}
                        </div>
                        <div className="agree_row">
                            <Input
                                id="agree_bt"
                                onChange={checkedAgree}
                                name="agree"
                                type="checkbox"
                                checked={formState.agree}
                            />
                            <label for="agree_bt">I agree to FRIIM’s Privacy Policy and Term &amp; Conditions. I also 
                            agree to receive periodic emails related to product or services and can unsubscribe at any time</label> 
                        </div>
                        <Button ref={checkButton} id="contactUs_bt">Contact Us</Button>
                    </Form>
                </div>
            </div>
        </div>

        <div id="section_map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.130859027557!2d126.89315021611353!3d37.48123823676453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9e2403fb2d4d%3A0xce157c886ce17856!2z7ISc7Jq47Yq567OE7IucIOq1rOuhnOq1rCDrlJTsp4DthLjroZwyNuq4uCA2MQ!5e0!3m2!1sko!2skr!4v1644485834792!5m2!1sko!2skr" width="100%" height="600"></iframe>
        </div>
    </Fragment>
    )
}

export default DevelopmentHistory;
