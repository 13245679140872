import React, {Fragment, useEffect, useState, useRef} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';
import { scroller } from 'react-scroll';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';
import { NAVIGATION_OFF } from '../../constants/actions';

import subBg from '../../img/sub/customerSupport_bg.jpg';
import blackBg from '../../img/sub/friim_sub_bg_1.jpg';

import { getSecurityReports } from "../../actions/board/securityReport";
import { getReleaseNotes } from '../../actions/board/releaseNote';
import { getVideos } from '../../actions/board/video';


const CustomerSupportCenter = ({location}) => {

    const dispatch = useDispatch();
    const sectionVideoRef = useRef();
    const { list:securityReports } = useSelector(state => state.securityReport);
    const { list:releasenotes } = useSelector(state => state.releaseNote);
    const { list:videos } = useSelector(state => state.video);
    const { navigation } = useSelector(state => state.common);
    const [ rnPage, setRnPage ] = useState(0);
    const [ srPage, setSrPage ] = useState(0);
    const [ tabIdx, setTabIdx ] = useState(0);
    const [ modal, setModal ] = useState({
        isModal : false,
        modalIdx : 0
    });

    // const tabs = [
    //     videos.map((video) => {
    //         { 
    //             "title" : video.title,
    //             "contents" : <iframe src="https://www.youtube.com/embed/ZIgz62G2vA8" width="100%" height="100%" title="Friim Introduction eng" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-pucture"></iframe>
    //         }
    //     })
    // ];

    const items = [
        require('../../img/sub/brochure_1.jpg').default, 
        require('../../img/sub/brochure_2.jpg').default,
        require('../../img/sub/brochure_3.jpg').default,
        require('../../img/sub/brochure_4.jpg').default,
        require('../../img/sub/brochure_5.jpg').default,
        require('../../img/sub/brochure_6.jpg').default,
        require('../../img/sub/brochure_7.jpg').default,
        require('../../img/sub/brochure_8.jpg').default
    ];

    const settings = {
        dots : true,
        Infinite : true,
        speed : 400,
        slidesToShow : 5,
        slidesToScroll : 1,
        adaptiveHeight : true,
        centerMode : true,
    };

    const mobileSettings = {
        dots : true,
        Infinite : true,
        speed : 400,
        slidesToShow : 1,
        slidesToScroll : 1,
        adaptiveHeight : true,
        centerMode : true,
    };


    const StyleSlider = styled(Slider)`
        .slick-track {
            display:flex;
        }
        .slick-prev, .slick-next {
            width:30px; height:30px;
        }
        .slick-prev {
            left:-35px;
        }
        .slick-next {
            right:-35px;
        }
        .slick-prev:before, .slick-next:before {
            font-size:30px;
        }
        .slick-dots li.slick-active button:before {
            opacity:.75;
            color:#631078;
        }
        .slick-dots li button::before {
            color:rgb(99,16,120)
        }
        .slick-dots {
            bottom:-60px;
        }
    `

    const openModal = (idx) => {
        setModal({...modal, isModal : !modal.isModal, modalIdx: idx});
    }

    const tabHandler = (idx) => {
        setTabIdx(idx);
    }

    const getSecurityReportsByPage = (state) => {
        if(state === "next") {
            setSrPage(srPage+1);
        } else {
            setSrPage(srPage-1);
        }
    }

    const getReleaseNotesByPage = (state) => {
        if(state === "next") {
            setRnPage(rnPage+1);
        } else {
            setRnPage(rnPage-1);
        }
    }
    
    var regExp = /^.*((youtube\/)|(v\/)|(\/u\/w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;

    const videoContent = () => {

        var youtubeId = videos.page.content[tabIdx].videoUrl.match(regExp)[7];
        console.log(videos.page.content[tabIdx].videoUrl);
        console.log(youtubeId);

        return (
            <iframe src={`https://www.youtube.com/embed/${youtubeId}`} width="100%" height="100%" title={videos.page.content[tabIdx].title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-pucture"></iframe>
        )
    };

    // useEffect(() => {
    //     dispatch(getSecurityReports(srPage));
    // }, [srPage]);

    // useEffect(() => {
    //     dispatch(getReleaseNotes(rnPage));
    // }, [rnPage]);



    useEffect(() => {
        dispatch(getVideos());
    },[]);

    useEffect(() => {
        if(location.state !== undefined) {
            if(navigation) {
                dispatch({type:NAVIGATION_OFF});
            }
            var scrollId = location.state.scrollId;
            scroller.scrollTo(`${scrollId}`, {
                duration : 800,
                offset:-80,
                smooth:'easeInOutQuartz'
            });
        }
    },[location.state !== undefined ? location.state.scrollId : {}]);

    return (
     <Fragment>        
        {modal.isModal ? 
        <Fragment>
            <div className="brochure_modal_bg"></div>
            <div className="brochure_modal_wrap">
                <div className="brochure_modal_container">
                    <div className="brochure_modal_content">
                        <button className="brochure_modal_close" onClick={() => openModal()}>x</button>
                        <img src={require(`../../img/sub/brochure_${modal.modalIdx+1}.jpg`).default}/>
                    </div>
                </div>
            </div>
        </Fragment>
        : ''
        }

    <div className="sub_visual" style={{backgroundImage : `url(${subBg})`}}>
        <h1>Customer Support Center</h1>
    </div>
    
    <div className="productManual section-padding-50" id="section_manual">
        <h2>PRODUCT MANUAL</h2>
        <div>
            <Fade bottom delay={100} distance="30px">
            <div>
                <section>
                    <img src={require('../../img/sub/customerSupport_icon_1.svg').default} alt=""/>
                </section>
                <section>
                    <h5>Policy Manager</h5>
                    <p>Online Manual for FRIIM Policy Manager Version 1.0</p>
                    <span className="update">Update : July 21, 2021</span>
                    <span className="downloard"><Link to="/product/policy-manager">View Product</Link></span>
                </section>
            </div>
            </Fade>
            <Fade bottom delay={300} distance="30px">
            <div>
                <section>
                    <img src={require('../../img/sub/customerSupport_icon_2.svg').default} alt=""/>
                </section>

                <section>
                    <h5>Compliance Manager</h5>
                    <p>Online Manual for FRIIM Compliance Manager Version 1.0</p>
                    <span className="update">Update : July 21, 2021</span>
                    <span className="downloard"><Link to="/product/compliance-manager">View Product</Link></span>
                </section>
            </div>
            </Fade>
            <Fade bottom delay={500} distance="30px">
            <div>
                <section>
                    <img src={require('../../img/sub/customerSupport_icon_3.svg').default} alt=""/>
                </section>

                <section>
                    <h5>Network Inspector</h5>
                    <p>Online Manual for FRIIM Network Inspector Version 1.0</p>
                    <span className="update">Update : July 21, 2021</span>
                    <span className="downloard"><Link to="/product/network-inspector">View Product</Link></span>
                </section>
            </div>
            </Fade>
        </div>
    </div>

    <section className="friimVideo newsletter-area section-padding-100 bg-img jarallax" style={{backgroundImage : `url(${blackBg})`}} id="section_video">
        <h2>FRIIM DEMO VIDEO</h2>
        <div className="allWrap">   
        {videos.page != undefined ? 
            <Fragment>
                <div className="tabBox">
                {videos.page.content.map((video, idx) => {
                    return (
                        <p key={idx} className={"tab-link " + (idx === tabIdx ? 'current' : '')} onClick={() => tabHandler(idx)}>{video.title}</p>
                        )
                    })}
                </div>
            <div id="tab-1" className="tab-content current">{videoContent()}</div>
            </Fragment> 
        :''}  
            
        </div>
    </section>

    {/* <div className="customer_board" id="section_release">
        <h2>PRODUCT RELEASE & UPDATE NOTE</h2>
        <div className="board wrap_1400">
            <ul>
                <div className="board_arrow board_prev">
                    {srPage !== 0 ? 
                        <button onClick={() => getReleaseNotesByPage("prev")}><img className="prev_btn" src={require('../../img/sub/page_arrow.svg').default}/></button> 
                        : <img className="prev_btn" src={require('../../img/sub/page_arrow.svg').default} style={{opacity:'20%'}}/>
                    }
                </div>
                {releasenotes.page !== undefined ? releasenotes.page.content.map(sr => {
                    return (
                        <li key={sr.rowNumber}><a href="#">{sr.noticeTitle}<span>Update : {moment(sr.createdTimeAt).format('ll')}</span></a></li>
                    )
                }) : ''}
                <div className="board_arrow board_next">
                    {(releasenotes.page !== undefined ? releasenotes.page.totalPages -1 : 0) !== srPage ? 
                        <button onClick={() => getReleaseNotesByPage("next")}><img className="next_btn" src={require('../../img/sub/page_arrow.svg').default}/></button> 
                        : <img className="next_btn" src={require('../../img/sub/page_arrow.svg').default} style={{opacity:'20%'}}/>
                    }
                </div>
            </ul>
            
        </div>
        <h2 style={{marginTop : "100px"}} id="section_report"  ref={sectionVideoRef}>SECURITY REPORT</h2>
        <div className="board wrap_1400">
            <ul>
                <div className="board_arrow board_prev">
                    {srPage !== 0 ? 
                        <button onClick={() => getSecurityReportsByPage("prev")}><img className="prev_btn" src={require('../../img/sub/page_arrow.svg').default}/></button> 
                        : <img className="prev_btn" src={require('../../img/sub/page_arrow.svg').default} style={{opacity:'20%'}}/>
                    }
                </div>
                {securityReports.page !== undefined ? securityReports.page.content.map(sr => {
                    return (
                        <li key={sr.rowNumber}><a href="#">{sr.noticeTitle}<span>Update : {moment(sr.createdTimeAt).format('ll')}</span></a></li>
                    )
                }) : ''}
                <div className="board_arrow board_next">
                    {(securityReports.page !== undefined ? securityReports.page.totalPages -1 : 0) !== srPage ? 
                        <button onClick={() => getSecurityReportsByPage("next")}><img className="next_btn" src={require('../../img/sub/page_arrow.svg').default}/></button> 
                        : <img className="next_btn" src={require('../../img/sub/page_arrow.svg').default} style={{opacity:'20%'}}/>
                    }
                </div>
            </ul>
        </div>
    </div> */}

    <div className="brochure section-padding-100">
        <h2>BROCHURE</h2>
        
        <section className="center slider parent-container wrap_1400">
            <div className="pc">
            <StyleSlider {...settings}>
                {items.map((url, idx) => {
                    return(
                        <div key={idx}>
                            <a className="slider_btn" onClick={() => {openModal(idx)}}>
                                <img src={url}/>
                            </a>
                        </div>
                    )
                })}
            </StyleSlider>
            </div>
            <div className="mobile">
            <StyleSlider {...mobileSettings}>
                {items.map((url, idx) => {
                    return(
                        <div key={idx}>
                            <a className="slider_btn" onClick={() => {openModal(idx)}}>
                                <img src={url}/>
                            </a>
                        </div>
                    )
                })}
            </StyleSlider>
            </div>
        </section>
    </div>
    </Fragment>
    )
}

export default CustomerSupportCenter;
