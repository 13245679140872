import { SET_MESSAGE } from "../../constants/actions";
import boardService from "../../services/board/boardService";

export const createContact = (parameter) => (dispatch) => {
    return boardService.createContact(parameter).then(
        (response) => {
            if(response.success) {
                return true;
            } else {
                return false;
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};
