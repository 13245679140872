import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga";

import App from "./App";
import ScrollToTop from './ScrollToTop';
import RouteChangeTracker from './RouteChangeTracker';
import { store } from './store';

ReactGA.initialize("UA-221385215-1");

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop/>
      <RouteChangeTracker/>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
