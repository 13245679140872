import React, { Fragment, useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import slideBgImg from '../img/index/friim_bg_1.jpg';
import newsletterBg from '../img/index/friim_bg_2.jpg';
import strategy_img1 from '../img/index/policy_manager.png';
import strategy_img2 from '../img/index/compliance_manager.png';
import strategy_img3 from '../img/index/network_inspector.png';

const Home = () => {

    return (
    <Fragment>
    <div className="hero-area">
        <div className="hero-slideshow owl-carousel">
            <div className="single-slide bg-img">
                <div className="slide-bg-img bg-img bg-overlay" style={{ backgroundImage : `url(${slideBgImg})`}}></div>
                    
                <div className="welcome-text text-center wrap_1400">
                    <div>
                        <h2 data-animation="fadeInUp" data-delay="300ms">Best Choice for Cloud Security!</h2>
                        <p data-animation="fadeInUp" data-delay="500ms"> FRIIM은 클라우드 상의 보안 관리 및 다양한 위협에 대응하기 위한 Multi and Hybrid Cloud 전용 통합보안 솔루션 입니다.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section className="newsletter-area section-padding-100 bg-img jarallax" style={{ backgroundImage : `url(${newsletterBg})` }}>
        <div> 
            <div className="nl-content text-center wrap_1400">
                <Fade bottom><h2 data-delay="100ms">Development Background</h2></Fade>
                <Fade bottom delay={300} distance="50px">
                        <p data-delay="300ms">최근의 추세는 On-premise System에서 Cloud System으로 급변하는 시기에 도달해 있습니다.<br/>
                    이러한 변화 속에서 우리는 On-premise 상에서는 경험하지 못한 심각한 보안 위협을 Cloud System에서는 직면하고 있습니다.<br/>
                    많은 기업들은 클라우드 서비스 프로바이더가 당연히 모든 보안 문제를 처리할 것이라고 생각하고 있습니다. <br/>
                    그러나, 클라우드 서비스 프로바이더는 고객의 기대와 달리 기본적인 보안 이슈에 대해서만 관여할 뿐, <br/>각각의 기업은 자신의 서비스를 위한 보안 대책을 스스로 가지고 있어야 합니다.
                        </p>
                </Fade>
                <Fade bottom delay={500}>
                    <h5 data-delay="500ms">Seekers는 Cloud 상의 보안 이슈를 하나의 System에서 해결하기 위하여 FRIIM을 개발하였습니다.</h5>
                </Fade>
            </div>
        </div>
    </section>

    <div className="features">
        <section className="features-area wrap_1400">
               <div className="features_left">
                    <div>
                      <h2>FEATURES OF FRIIM</h2>
                      <h6></h6>
                    </div>
               </div>
               <div className="features_right">
                   <div><img src={require('../img/index/features_icon_1.svg').default} alt=""/><span>MONITORING</span></div>
                   <div><img src={require('../img/index/features_icon_2.svg').default} alt=""/><span>VISUALITY</span></div>
                   <div><img src={require('../img/index/features_icon_3.svg').default} alt=""/><span>Quick configuration</span></div>
                   <div><img src={require('../img/index/features_icon_4.svg').default} alt=""/><span>Consistent policy</span></div>
                   <div><img src={require('../img/index/features_icon_5.svg').default} alt=""/><span>PERFOrmance Check</span></div>
                   <div><img src={require('../img/index/features_icon_6.svg').default} alt=""/><span>compliance</span></div>
                   <div><img src={require('../img/index/features_icon_7.svg').default} alt=""/><span>network replay</span></div>
               </div>
        </section>
    </div>

    <section className="strategy-area">
        <Fade bottom>
        <h2 style={{marginBottom:"40px"}} className="wow fadeInUp" data-delay="100ms">FRIIM's strategy for 11 major cloud security threats</h2>
        </Fade>
        <Fade bottom cascade delay={300}>
        <div>
            <div className="strategy_wrap">
                <div className="small_box empty">
                    
                </div>
                <div className="small_box">
                    <span>1</span>
                    <p>데이터 침해</p>
                </div>
                <div className="small_box">
                    <span>2</span>
                    <p>잘못된 구성과 부적절한 정책변경 관리</p>
                </div>
                <div className="small_box">
                    <span>3</span>
                    <p>클라우드 보안 아키텍처와 전략 미흡</p>
                </div>
                <div className="big_box pc" style={{background: `url(${strategy_img1}) no-repeat 25px center #1f1f1f`}}>
                    <span>Policy<br/> Manager</span>
                </div>
                <div className="big_box mobile">
                    <span>Policy Manager</span>
                </div>
            </div>
            <div className="strategy_wrap">
                <div className="small_box">
                    <span>4</span>
                    <p>ID, 크리덴셜, 액세스, 키 관리 불충분</p>
                </div>
                <div className="small_box">
                    <span>5</span>
                    <p>계정관리</p>
                </div>
                <div className="small_box">
                    <span>6</span>
                    <p>내부자 위협</p>
                </div>
                <div className="small_box">
                    <span>7</span>
                    <p>불완전한 인터페이스와 API</p>
                </div>
                <div className="big_box pc" style={{background: `url(${strategy_img2}) no-repeat 25px center #1f1f1f`}}>
                    <span>Compliance<br/> Manager</span>
                </div>
                <div className="big_box mobile">
                    <span>Compliance Manager</span>
                </div>
            </div>
            <div className="strategy_wrap">
                <div className="small_box" data-delay="750ms">
                    <span>8</span>
                    <p>취약한 제어 영역</p>
                </div>
                <div className="small_box" data-delay="800ms">
                    <span>9</span>
                    <p>메타스트럭처와 애플리스트럭처의 실패</p>
                </div>
                <div className="small_box" data-delay="850ms">
                    <span>10</span>
                    <p>클라우드 사용과 관련된 가시성의 제한 문제</p>
                </div>
                <div className="small_box" data-delay="900ms">
                    <span>11</span>
                    <p>클라우드 서비스 오용 및 악용</p>
                </div>
                <div className="big_box pc" data-delay="950ms" style={{background: `url(${strategy_img3}) no-repeat 25px center #1f1f1f`}}>
                    <span>Network<br/> Inspector</span>
                </div>
                <div className="big_box mobile">
                    <span>Network Inspector</span>
                </div>
            </div>
        </div>
        </Fade>
    </section>
    </Fragment>
    )
}

export default Home;
