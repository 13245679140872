import React, {Fragment} from 'react';
import Fade from 'react-reveal/Fade';
import subBg from '../../img/sub/product_bg.jpg';
import hexagon_pattern from '../../img/sub/hexagon_pattern1.png';

const NetworkInspector = ({}) => {

    return (
     <Fragment>
        <div className="sub_visual" style={{backgroundImage: `url(${subBg})`}}>
            <h1>Network Inspector</h1>
        </div>
    
        <div className="product_bg jarallax" style={{backgroundImage: `url(${hexagon_pattern})`}}>
            <div className="wrap_1400">
                <h2>FRIIM Network Inspector</h2>
                <p>Network Inspector는 잠재적인 위협을 방지하기 위해 클라우드 네트워크의 모든 패킷을 수집하고 저장합니다.</p>
                
                <div className="networkInspector">
                    <ul>
                        <Fade bottom delay={100} distance="30px"><li>Batch Policy Management</li></Fade>
                        <Fade bottom delay={300} distance="30px"><li>Data Collector</li></Fade>
                        <Fade bottom delay={500} distance="30px"><li>History <br/>Management and<br/> Restorantion</li></Fade>
                        <Fade bottom delay={700} distance="30px"><li>Authetication Management</li></Fade>
                        <Fade bottom delay={900} distance="30px"><li>Security Analysis</li></Fade>
                        <Fade bottom delay={1100} distance="30px"><li>Provider<br/> Security<br/> Management</li></Fade>
                    </ul>
                </div>
            </div>
        </div>

        <div className="sub_section2 networkInspector section-padding-50">
            <div className="wrap_1400">
                <section>
                    <h5>1.사전 위협 대응</h5>
                    <p>보안 관리자는 사전 정의된 위협을 기반으로 수집된 패킷에서 위협을 확인할 수 있습니다.</p>
                </section>
                <section>
                    <h5>2. 신속한 워크로드 상태 식별</h5>
                    <p>FRIIM은 보안관리자가 빠르게 워크로드 현황을
                    파악할 수 있는 화면을 제공합니다.</p>
                </section>
                <section>
                    <h5>3. 정확한 사후검증</h5>
                    <p>FRIIM은 네트워크의 모든 패킷을 수집하며,
                    사용자는 "Network Replay"를 통해 문제가 발생한
                    정확한 시간과 원인을 파악할 수 있습니다.</p>
                </section>
                <section>
                    <h5> 4. 위협 및 오용,남용의 차단 </h5>
                    <p>수집된 패킷을 기반의한 사전 식별 통하여 데이터
                    누출, 오용 및 남용을 파악, 방지합니다.</p>
                </section>
            </div>
        </div>

        <div className="sub_section3_monitor section-padding-100 wrap_1400">
            <div>
                <img src={require('../../img/sub/product_3_friim_1.png').default} alt=""/>
                <div className="section-padding-50">
                    <div className="monitor_li">
                        <span>1</span><p> You can check the topology of the particular office if you click that office at the hole topology.</p>
                    </div>
                    <div className="monitor_li">
                        <span>2</span><p>The colors indicate the states of each instance(VM) in the unit of subnet or department. If you click the particular instance, you can check the states of that instance on the detail page.</p>
                    </div>
                </div>
            </div>
            <div>
                <img src={require('../../img/sub/product_3_friim_1.png').default} alt=""/>
                <div className="section-padding-50">
                    <div className="monitor_li">
                        <span>1</span><p>Native & 3rd Party 보안 솔루션의 로그를 하나의 화면에서 통합 관리할 수 있습니다.</p>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
    )
}

export default NetworkInspector;
