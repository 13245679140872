import  { MANUAL_TYPE, MANUAL_TYPES } from "../../constants/boardActions";

const initialState = {
    data: {},
    list: {}
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case MANUAL_TYPE:
            return {
                ...state,
                data: payload,
            };
        case MANUAL_TYPES:
            return {
                ...state,
                list: payload,
            };  
        default:
            return state;
    }
}
