import React, { useEffect } from 'react';

const NotFound = props => {

    return (
        <div className="main-contents">
            <div className="not_found_wrap">
                <h1><span>404</span> <br/>ERROR</h1>
                <p>찾을 수 없는 페이지입니다.<br/>요청하신 페이지가 사라졌거나, 잘못된 경로를 이용하셨습니다.</p>
                {/* <button onClick={}>이전 페이지로</button> */}
            </div>
        </div>
    )
}

export default NotFound;
