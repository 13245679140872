import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login } from "../actions/auth";
import { HEADER, LOADING_ON, LOADING_OFF } from '../constants/actions';
import { required } from './Validation';
import NotContent from './NotContent';

import './css/Login.css'

const Login = props => {
    const dispatch = useDispatch();

    const form = useRef();
    const checkButton = useRef();
    const inputRef = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setIsError] = useState(false);

    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
        setIsError(false);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
        setIsError(false);
    };

    const handleLogin = (e) => {
        e.preventDefault();
        form.current.validateAll();

        if (checkButton.current.context._errors.length === 0) {
            dispatch(login(username, password))
                .then(() => {
                    if(props.location.state) {
                        props.history.push(props.location.state.from.pathname);
                    } else {
                        props.history.push('/');
                    }
                })
                .catch(() => {
                    setIsError(true);
                    dispatch({ type: LOADING_OFF })
                });
        } else {
            dispatch({ type: LOADING_OFF })
        }
    };

    useEffect(() => {
        dispatch({ type: HEADER })
        return () => {
            dispatch({ type: HEADER })
        };
    }, []);

    return (
        <NotContent />
        // <div className="main-container">
        //     <div className="login-container">
        //         <div className="logo-contents">
        //             <img src="/logos/logo_ph.svg" alt="friim 로고" />
        //         </div>
        //         <div className="input-contents">
        //             <div className="signin">
        //                 <Form onSubmit={handleLogin} ref={form}>
        //                     <div className="login-form" ref={inputRef}>
        //                         <Input
        //                             onChange={onChangeUsername}
        //                             value={username}
        //                             validations={[required]}
        //                             type="text"
        //                             className="login-input"
        //                             placeholder="Email"
        //                             autoFocus />
        //                     </div>
        //                     <div className="login-form">
        //                         <Input
        //                             onChange={onChangePassword}
        //                             value={password}
        //                             validations={[required]}
        //                             type="password"
        //                             className="password-input"
        //                             placeholder="Password"
        //                         />
        //                         {isError && 
        //                             <p className="status-error">* 아이디 혹은 비밀번호가 틀렸습니다.</p>
        //                         }
        //                     </div>
        //                     <div>
        //                         <button className="btn login-btn" tabIndex="0">로그인</button>
        //                     </div>
        //                     <CheckButton style={{ display: "none" }} ref={checkButton} />
        //                 </Form>
        //                 <div className="signin-form">
        //                     <button className="btn signup-btn" tabIndex="0">회원가입</button>
        //                 </div>
        //             </div>
        //             <div className="easy-login">
        //                 <a href="#" className="easy-login-content" title="구글">
        //                     <img src="/images/google_circle_icon.png" />
        //                     <p>Google</p>
        //                 </a>
        //                 <a href="#" className="easy-login-content" title="페이스북">
        //                     <img src="/images/facebook_circle_icon.png" />
        //                     <p>페이스북</p>
        //                 </a>
        //             </div>
        //             <div className="advertisment">
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
};

export default Login;