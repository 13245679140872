import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import common from './reducers/common';
import message from './reducers/message';
import auth from './reducers/auth';
import securityReport from './reducers/board/securityReportReducer';
import manual from './reducers/board/manualReducer';
import manualType from './reducers/board/manualTypeReducer';
import video from './reducers/board/videoReducer';
import releaseNote from './reducers/board/releaseNoteReducer';
import cloudSecurityDoc from './reducers/board/cloudSecurityDocReducer';

export default combineReducers({
    router: routerReducer,
    common,
    auth,
    message,
    securityReport,
    manual,
    manualType,
    video,
    releaseNote,
    cloudSecurityDoc
});
