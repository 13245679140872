import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        // Copywrite Area
        <div className="copywrite-area">
            
            <div className="footer pc wrap_1400">
                <div className="footer_left"><img src={require('../img/core-img/footer_logo.svg').default} alt=""/></div>
                <div className="footer_right">
                    <dl>
                        <dt><Link to="/product/policy-manager" title="Policy Manager">제품</Link></dt>
                        <dd><Link to="/product/policy-manager" title="Policy Manager">- Policy Manager</Link></dd>
                        <dd><Link to="/product/compliance-manager" title="Compliance Manager">- Compliance Manager</Link></dd>
                        <dd><Link to="/product/network-inspector" title="Network Inspector">- Network Inspector</Link></dd>
                    </dl>
                    <dl>
                        <dt><Link to="/cloud-security">클라우드 보안</Link></dt>
                        <dd><Link to={{ pathname :`/cloud-security`, state : {scrollId : 'section_gartner'}}}>- 클라우드 보안전략(가트너)</Link></dd>
                        <dd><Link to={{ pathname :`/cloud-security`, state : {scrollId : 'section_cspm'}}}>- CSPM</Link></dd>
                        <dd><Link to={{ pathname :`/cloud-security`, state : {scrollId : 'section_cwpp'}}}>- CWPP</Link></dd>
                        <dd><Link to={{ pathname :`/cloud-security`, state : {scrollId : 'section_casb'}}}>- CASB</Link></dd>
                        <dd><Link to={{ pathname :`/cloud-security`, state : {scrollId : 'section_doc'}}}>- 클라우드 보안문서</Link></dd>
                    </dl>
                     <dl>
                        <dt><Link to="/customer-support-center">고객지원</Link></dt>
                        <dd><Link to={{ pathname :`/customer-support-center`, state : {scrollId : 'section_manual'}}}>- 제품 매뉴얼</Link></dd>
                        {/* <dd>- 릴리즈 노트</dd> */}
                        <dd><Link to={{ pathname :`/customer-support-center`, state : {scrollId : 'section_video'}}}>- 데모영상</Link></dd>
                        {/* <dd>- 보안 리포트</dd> */}
                    </dl>
                    <dl>
                        <dt><Link to="/company">회사소개</Link></dt>
                        <dd><Link to={{ pathname :`/company`, state : {scrollId : 'section_history'}}}>- 개발연혁</Link></dd>
                        <dd><Link to={{ pathname :`/company`, state : {scrollId : 'section_contact_us'}}}>- Contact Us</Link></dd>
                        <dd><Link to={{ pathname :`/company`, state : {scrollId : 'section_map'}}}>- 오시는길</Link></dd>
                    </dl>
                    <dl>
                        <dt><Link to="/pricing">요금</Link></dt>
                        <dd><Link to={{ pathname :`/pricing`, state : {scrollId : 'section_policy_manager_price'}}}>- Policy Manager</Link></dd>
                        <dd><Link to={{ pathname :`/pricing`, state : {scrollId : 'section_compliance_price'}}}>- Compliance Manager</Link></dd>
                        <dd><Link to={{ pathname :`/pricing`, state : {scrollId : 'section_network_inspector_price'}}}>- Network Inspector</Link></dd>
                    </dl>

                </div>
            </div>
            <div className="footer_bottom">
                {/* Copywrite Text */}
                <p className="copywrite-text " style={{margin:'0 auto'}}>
Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved </p>
            </div>
        </div>
    )
}
     
export default Footer;
