import http from "../http-common";

const register = (username, email, password) => {
    return http.client
        .post("/api/signup", {
            username,
            email,
            password,
        });
};

const login = (username, password) => {
    const parameters = {
        userEmail: username,
        password: password
    };
    return http.client
        .post("/api/auth/oauth2/token/cookie", parameters)
        .then((response) => {
            // if (response.status === 200) {
            //     const token = `Bearer ${response.data.accessToken}`
            //     http.client.defaults.headers.common['Authorization'] = token;
            // }
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("isLoggedIn");
    return http.client
        .post("/api/auth/logout")
        .then((response) => {
            return response.data;
        });
};

const refresh = () => {
    return http.client
        .post("/api/auth/oauth2/token/refresh/cookie")
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            return false;
        });
};


export default {
    register,
    login,
    logout,
    refresh,
};
