import React, {Fragment} from 'react';
import Fade from'react-reveal/Fade';
import subBg from '../../img/sub/product_bg.jpg';
import hexagon_pattern from '../../img/sub/hexagon_pattern2.png';

const ComplianceManager = ({}) => {

    return (
     <Fragment>        
        <div className="sub_visual" style={{backgroundImage: `url(${subBg})`}}>
            <h1>Compliance Manager</h1>
        </div>
        <div className="product_bg jarallax" style={{backgroundImage: `url(${hexagon_pattern})`}}>
            <div className="wrap_1400">
                <h2>FRIIM Compliance Manager</h2>
                <p>FRIIM Compliance Manager는 수 많은 리소스에 대한 컴플라이언스 준수 상황을 모니터링 하고 준수를 위한 효과적 설정을 지원합니다.</p>
                
                <div className="friimComplianceManager section-padding-50">
                    <ul>
                        <Fade bottom delay={100} distance="30px"><li>계정상태 모니터링</li></Fade>
                        <Fade bottom delay={300} distance="30px"><li>멀티 클라우드 모니터링</li></Fade>
                        <Fade bottom delay={500} distance="30px"><li>컴플라이언스</li></Fade>
                        <Fade bottom delay={700} distance="30px"><li>대시보드</li></Fade>
                        <Fade bottom delay={900} distance="30px"><li>보안정책 승인 프로세스</li></Fade>
                        <Fade bottom delay={1100} distance="30px"><li>리소스에 대한 중앙화된 관리체계</li></Fade>
                    </ul>
                </div>
            </div>
        </div>

        <div className="sub_section2 complianceManager section-padding-50">
        <div className="wrap_1400">
                <section>
                    <h5>1. Security Management Responsibilities Clarity</h5>
                    <p>FRIIM은 시스템의 높은 보안수준 유지를 위하여 API 키를 암호화 하여 통신합니다.</p>
                </section>
                <section>
                    <h5>2. High visibility</h5>
                    <p>사용자는 한 화면에서 인스턴스와 VPC를 확인할 수 있어, 클라우드 시스템의 전반적인 상태를 신속하게 파악할 수 있습니다.</p>
                </section>
                <section>
                    <h5> 3. Centralization of security policy management </h5>
                    <p>사용자는 정책의 일괄 배포를 통하여 클라우드 시스템에 대한 보안 정책 및 위협에 대한 일관된 관리가 가능합니다.</p>
                </section>
                <section>
                    <h5> 4. Security Management Responsibilities  </h5>
                    <p>보안정책 뿐만 아니라 컴플라이언스에 대한
                    준수여부 확인 및 신속한 식별이 가능합니다.</p>
                </section>
            </div>
        </div>

        <div className="sub_section3_monitor section-padding-100 wrap_1400">
            <div>
                <img src={require('../../img/sub/product_2_friim_1.png').default} alt=""/>
                <div className="section-padding-50">
                    <div className="monitor_li">
                        <span>1</span><p>각 인스턴스, 서브넷, VPC 등 각각의 프로바이더 및 계열사별 통합 토폴로지를 제공하여,네트워크 구성에 대한 가시성을 높이고 구성도 상에서 자원의 상태를 쉽게 확인할 수 있습니다.</p>
                    </div>
                    <div className="monitor_li">
                        <span>2</span><p>각 인스턴스, 서브넷, VPC 등에 대한 상태를 상세 페이지에서 확인할 수 있습니다.</p>
                    </div>
                    <div className="monitor_li">
                        <span>3</span><p>네트워크 상에서 사용되는 ping, trace route, nslookup과 같은 간단한 명령어 실행이 가능합니다.</p>
                    </div>
                </div>
            </div>
            <div>
                <img src={require('../../img/sub/product_2_friim_2.png').default} alt=""/>
                <div className="section-padding-50">
                    <div className="monitor_li">
                        <span>1</span><p>각각의 리소스 컴플라이언스 준수 상황을 리소스 기준 또는 컴플라이언스 기준으로 파악할 수 있습니다.</p>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
    )
}

export default ComplianceManager;
