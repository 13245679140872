import React, { useEffect } from 'react';

const NotContent = ({}) => {

    return (
        <div className="main-contents">
            <div className="not_content_wrap">
                <h1>현재 <span>서비스 준비중</span> 입니다.</h1>
                <p>보다 나은 서비스 제공을 위하여 페이지 준비중에 있습니다.<br/>빠른 시일 내에 준비하여 찾아뵙겠습니다.</p>
                {/* <button onClick={}>이전 페이지로</button> */}
            </div>
        </div>
    )
}

export default NotContent;
