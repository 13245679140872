import React, {useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NAVIGATION, NAVIGATION_OFF } from '../constants/actions';

const Header = ({location}) => {
    const dispatch = useDispatch();

    const initMobileSubmenu = {
        sub1 : false,
        sub2 : false,
        sub3 : false,
        sub4 : false,
        sub5 : false
    }

    const [scrollPosition, setScrollPosition] = useState(0);
    const { navigation } = useSelector(state => state.common);
    const [mobileSubMenu, setMobileSubMenu] = useState(initMobileSubmenu);

    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }

    useEffect(() => {
        if(window.location.pathname !== '/' && navigation) {
            dispatch({type:NAVIGATION_OFF});
        }
    },[window.location.pathname]);

    useEffect(() => {
        if(!navigation) {
            setMobileSubMenu(initMobileSubmenu);
        }
    },[navigation]);

    useEffect(() => {
        window.addEventListener('scroll', updateScroll);
    });


    return (
        <header>
        {/* PC Nav */}
        <div className={"navbar " + (scrollPosition > 1 ? "fixed_navbar" : "")}>

            <div className="logo">
                <Link to="/">
                    <img src={require('../img/core-img/friim_logo.svg').default} alt=""/>
                </Link>
            </div>

            <div className="menu">
                <ul>
                   <li><Link to="/product/policy-manager">제품</Link>
                        <div className="dropdown">
                            <div className="dropdown_left">
                                <ul>
                                    <li><img src={require('../img/core-img/menu_1_product_1.svg').default} alt="Policy Manager 아이콘"/>
                                        <Link to="/product/policy-manager" title="Policy Manager">Policy Manager</Link>
                                    </li>
                                    <li><img src={require('../img/core-img/menu_1_product_2.svg').default} alt="Compliance Manager 아이콘"/>
                                        <Link to="/product/compliance-manager" title="Compliance Manager">Compliance Manager</Link>
                                    </li>
                                    <li><img src={require('../img/core-img/menu_1_product_3.svg').default} alt="Network Inspector 아이콘"/>
                                        <Link to="/product/network-inspector" title="Network Inspector">Network Inspector</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="dropdown_right">
                                <div>
                                    <h6>제품</h6>
                                    <img src={require('../img/core-img/menu_1_product.jpg').default} alt="제품 메뉴 이미지"/>
                                </div>
                            </div>
                        </div>
                    </li>
                    
                    <li><Link to="/cloud-security">클라우드 보안</Link>
                        <div className="dropdown">
                            <div className="dropdown_left">
                                <ul>
                                    <li>
                                        <img src={require('../img/core-img/menu_2_cloud_1.svg').default} alt="클라우드 보안전략 아이콘"/>
                                        <Link to={{ pathname :`/cloud-security`, state : {scrollId : 'section_gartner'}}}>클라우드 보안전략(가트너)</Link>
                                    </li>
                                    <li>
                                        <img src={require('../img/core-img/menu_2_cloud_2.svg').default} alt="CSPM 아이콘"/>
                                    <Link to={{ pathname :`/cloud-security`, state : {scrollId : 'section_cspm'}}}>CSPM</Link>
                                    </li>
                                    <li>
                                        <img src={require('../img/core-img/menu_2_cloud_3.svg').default} alt="CWPP 아이콘"/>
                                    <Link to={{ pathname :`/cloud-security`, state : {scrollId : 'section_cwpp'}}}>CWPP</Link>
                                    </li>
                                    <li>
                                        <img src={require('../img/core-img/menu_2_cloud_4.svg').default} alt="CASB 아이콘"/>
                                    <Link to={{ pathname :`/cloud-security`, state : {scrollId : 'section_casb'}}}>CASB</Link>
                                    </li>
                                    <li>
                                        <img src={require('../img/core-img/menu_2_cloud_5.svg').default} alt="클라우드 보안문서 아이콘"/>
                                        <Link to={{ pathname :`/cloud-security`, state : {scrollId : 'section_doc'}}}>클라우드 보안문서</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="dropdown_right">
                                <div>
                                    <h6>클라우드 보안</h6>
                                    <img src={require('../img/core-img/menu_2_cloud.jpg').default} alt="클라우드 보안 메뉴 이미지"/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li><Link to="/customer-support-center">고객지원</Link>
                        <div className="dropdown">
                            <div className="dropdown_left">
                                <ul>
                                    <li>
                                        <img src={require('../img/core-img/menu_3_support_1.svg').default} alt="제품 메뉴얼 아이콘"/>
                                        <Link to={{ pathname :`/customer-support-center`, state : {scrollId : 'section_manual'}}}>제품 매뉴얼</Link>
                                    </li>
                                    <li>
                                        <img src={require('../img/core-img/menu_3_support_3.svg').default} alt="데모영상 아이콘"/>
                                        <Link to={{ pathname :`/customer-support-center`, state : {scrollId : 'section_video'}}}>데모 영상</Link>
                                    </li>
                                    {/* <li>
                                        <img src={require('../img/core-img/menu_3_support_2.svg').default} alt="릴리즈 노트 아이콘"/>
                                        <Link to={{ pathname :`/customer-support-center`, state : {scrollId : 'section_release'}}}>릴리즈 노트</Link>
                                    </li>
                                    <li>
                                        <img src={require('../img/core-img/menu_3_support_4.svg').default} alt="보안리포트 아이콘"/>
                                        <Link to={{ pathname :`/customer-support-center`, state : {scrollId : 'section_report'}}}>보안 리포트</Link>
                                    </li> */}
                                </ul>
                            </div>
                        <div className="dropdown_right">
                            <div>
                                <h6>고객지원</h6>
                                <img src={require('../img/core-img/menu_3_support.jpg').default} alt="고객지원 메뉴 이미지"/>
                            </div>
                        </div>
                        </div>
                    </li>
                    <li><Link to="/company">회사소개</Link>
                        <div className="dropdown">
                            <div className="dropdown_left">
                                <ul>
                                    <li>
                                        <img src={require('../img/core-img/menu_4_aboutus_1.svg').default} alt="제품 메뉴얼 아이콘"/>
                                        <Link to={{ pathname :`/company`, state : {scrollId : 'section_history'}}}>개발연혁</Link>
                                    </li>
                                    <li>
                                        <img src={require('../img/core-img/menu_4_aboutus_2.svg').default} alt="Contact Us 아이콘"/>
                                        <Link to={{ pathname :`/company`, state : {scrollId : 'section_contact_us'}}}>Contact Us</Link>
                                    </li>
                                    <li>
                                        <img src={require('../img/core-img/menu_4_aboutus_3.svg').default} alt="오시는 길 아이콘"/>
                                        <Link to={{ pathname :`/company`, state : {scrollId : 'section_map'}}}>오시는길</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="dropdown_right">
                                <div>
                                    <h6>고객지원</h6>
                                    <img src={require('../img/core-img/menu_4_aboutus.jpg').default} alt="회사소개 메뉴 이미지"/>
                                </div>
                            </div>
                        </div> 
                    </li>
                    <li><Link to="/pricing">요금</Link>
                        <div className="dropdown">
                            <div className="dropdown_left">
                                <ul>
                                    <li>
                                        <img src={require('../img/core-img/menu_5_price_1.svg').default} alt="Policy Manager 아이콘"/>
                                        <Link to={{ pathname :`/pricing`, state : {scrollId : 'section_policy_manager_price'}}}>Policy Manager</Link>
                                    </li>
                                    <li>
                                        <img src={require('../img/core-img/menu_5_price_2.svg').default} alt="Compliance Manager 아이콘"/>
                                        <Link to={{ pathname :`/pricing`, state : {scrollId : 'section_compliance_price'}}}>Compliance Manager</Link>
                                    </li>
                                    <li>
                                        <img src={require('../img/core-img/menu_5_price_3.svg').default} alt="Network Inspector 아이콘"/>
                                        <Link to={{ pathname :`/pricing`, state : {scrollId : 'section_network_inspector_price'}}}>Network Inspector</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="dropdown_right">
                                <div>
                                    <h6>요금</h6>
                                    <img src={require('../img/core-img/menu_5_price.jpg').default} alt="요금 메뉴 이미지"/>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="gnb_right">
                <div className="gnb_button">
                    <div className="contact">
                        <Link to={{ pathname :`/company`, state : {scrollId : 'section_contact_us'}}} props="contactUs"> CONTACT US</Link>
                    </div>
                    <div className="console">
                        <a href="https://console.friim.com/login" target="_blank">CONSOLE</a>
                    </div>
                </div>
                <div className="sns_wrap">
                    <div className="sns_item sns_youtube"><a href="https://www.youtube.com/channel/UCjqoY4lJTaxEaq38r1AOYDw" target="_blank"><img src={require('../img/core-img/sns_01_youtube_icon.png').default} alt="유튜브 아이콘"/></a></div>
                    <div className="sns_item sns_facebook"><a href="https://www.facebook.com/FRllM" target="_blank"><img src={require('../img/core-img/sns_02_facebook_icon.png').default} alt="페이스북 아이콘"/></a></div>
                </div>      
            </div>
        </div>
        {/* PC Nav end */}

        {/* Mobile Nav Start */}
        <div className="mobileMenuWrapper">
          <div className="container">
             <div className="head">
               <div className="slide-open" id="slide-open" onClick={() => {dispatch({type : NAVIGATION})}}>
                  <span className={"burgur " + (navigation ? "on" : "")} id="burgur">
                  <span className="top-line"></span>
                  <span className="bot-line"></span>
               </span>
               </div>
              
               <div className="logo"><Link to="/">
                    <img src={require("../img/core-img/friim_logo.svg").default} alt=""/>
                </Link></div>
             </div>
               <div className={"slide " + (navigation ? "on" : "")} id="slide">
                 <div className="slide-title">
                    <img src={require("../img/core-img/friim_logo.svg").default} alt=""/>
                 </div>
                 <ul className="mobileMenu">
                   <li onClick={() => {setMobileSubMenu({...mobileSubMenu, sub1 : !mobileSubMenu.sub1})}}>
                        <a>제품</a>
                            <ul className={"mobileSubMenu " + (mobileSubMenu.sub1 ? "on" : "")}>
                                <li><img src={require("../img/core-img/menu_1_product_1.svg").default}/><Link to="/product/policy-manager">Policy Manager</Link></li>
                                    <li><img src={require("../img/core-img/menu_1_product_2.svg").default}/><Link to="/product/compliance-manager">Compliance Manager</Link></li>
                                    <li><img src={require("../img/core-img/menu_1_product_3.svg").default}/><Link to="/product/network-inspector">Network Inspector</Link></li>
                            </ul>
                   </li>
                   <li onClick={() => {setMobileSubMenu({...mobileSubMenu, sub2 : !mobileSubMenu.sub2})}}>
                        <Link to={{ pathname :`/cloud-security`}}>클라우드 보안</Link>
                   </li>
                   <li onClick={() => {setMobileSubMenu({...mobileSubMenu, sub3 : !mobileSubMenu.sub3})}}>
                    <Link to={{ pathname :`/customer-support-center`}}>고객지원</Link>
                    </li>
                   <li onClick={() => {setMobileSubMenu({...mobileSubMenu, sub4 : !mobileSubMenu.sub4})}}>
                    <Link to={{ pathname :`/company`}}>회사소개</Link>
                   </li>
                   <li onClick={() => {setMobileSubMenu({...mobileSubMenu, sub5 : !mobileSubMenu.sub5})}}>
                        <Link to={{ pathname :`/pricing`}}>요금</Link>
                   </li>
                   
                 </ul>
               </div>
          </div> 
        </div>
        {/* Mobile Nav End */}
    </header>
    )
}

export default Header;