import { SET_MESSAGE } from "../../constants/actions";
import { SECURITY_REPORT, SECURITY_REPORTS } from "../../constants/boardActions";
import boardService from "../../services/board/boardService";

export const getSecurityReport = (rowNumber) => (dispatch) => {
    return boardService.getSecurityReport(rowNumber).then(
        (response) => {
            dispatch({
                type: SECURITY_REPORT,
                payload: response,
            });

            return Promise.resolve();
        },
        (error) => {
            const message = 
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

export const getSecurityReports = (page) => (dispatch) => {
    return boardService.getSecurityReports(page).then(
        (response) => {
            dispatch({
                type: SECURITY_REPORTS,
                payload: response,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};
