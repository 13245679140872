import {
    NAVIGATION,
    NAVIGATION_OFF,
    HEADER,
    LOADING_ON,
    LOADING_OFF,
    DETAILPAGE_ON,
    DETAILPAGE_OFF,
    ADDPAGE_ON,
    ADDPAGE_OFF,
    MODIFYPAGE_ON,
    MODIFYPAGE_OFF,
    DISABLED

} from '../constants/actions';

const defaultState = {
    header: true,
    navigation: false,
    loading: false,
    detailPage: false,
    addPage: false,
    modifyPage : false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case HEADER:
            return {
                ...state,
                header: !state.header
            };
        case NAVIGATION:
            return {
                ...state,
                navigation: !state.navigation
            };
        case NAVIGATION_OFF:
            return {
                ...state,
                navigation: false
            };
        case LOADING_ON:
            return {
                ...state,
                loading: true
            };
        case LOADING_OFF:
            return {
                ...state,
                loading: false
            };
        case DETAILPAGE_ON:
            return {
                ...state,
                detailPage:true
            };
        case DETAILPAGE_OFF:
            return {
                ...state,
                detailPage:false
            };
        case ADDPAGE_ON:
            return {
                ...state,
                addPage:true
            };
        case ADDPAGE_OFF:
            return {
                ...state,
                addPage:false
            }
        case MODIFYPAGE_ON:
            return {
                ...state,
                modifyPage:true
            }
        case MODIFYPAGE_OFF:
            return {
                ...state,
                modifyPage:false
            }
        default:
            return state;
    }
};
