//security_reports
export const SECURITY_REPORT = "SECURITY_REPORT";
export const SECURITY_REPORTS = "SECURITY_REPORTS";

//manuals
export const MANUAL = "MANUAL";
export const MANUALS = "MANUALS";

//manual_types
export const MANUAL_TYPE = "MANUAL_TYPE";
export const MANUAL_TYPES = "MANUAL_TYPES";

//videos
export const VIDEO = "VIDEO";
export const VIDEOS = "VIDEOS";



//release_notes
export const RELEASE_NOTE = "RELEASE_NOTE";
export const RELEASE_NOTES = "RELEASE_NOTES";

//cloud_security_documents
export const CLOUD_SECURITY_DOC = "CLOUD_SECURITY_DOC";
export const CLOUD_SECURITY_DOCS = "CLOUD_SECURITY_DOCS";
