import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";

import Header from './components/Header';
import MoveTop from './components/MoveTop';
import Home from './components/Home';
import PolicyManager from './components/product/PolicyManager';
import ComplianceManager from './components/product/ComplianceManager';
import NetworkInspector from './components/product/NetworkInspector';
import CloudSecurity from "./components/cloudSecurity/CloudSecurity";
import Support from "./components/customerSupport/CustomerSupportCenter";
import History from "./components/company/DevelopmentHistory";
import Pricing from "./components/pricing/Pricing";
import Login from "./components/Login";
import NotFound from './components/NotFound';
import Footer from "./components/Footer";

import './components/css/reset.css'
import './components/css/style.css'
import './components/css/animate.css'

const App = () => {

    const { loading } = useSelector(state => state.common);

    return (
        <div id="body" className="focusoutline-hide">
            <div id="web-container">
                <Header/>
                <MoveTop/>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/product/policy-manager" component={PolicyManager} />
                    <Route path="/product/compliance-manager" component={ComplianceManager} />
                    <Route path="/product/network-inspector" component={NetworkInspector} />
                    <Route path="/cloud-security" component={CloudSecurity} />
                    <Route path="/customer-support-center" component={Support} />
                    <Route path="/company" component={History} />
                    <Route path="/pricing" component={Pricing} />
                    <Route path="/login" component={Login} />
                    <Route component={NotFound} />
                </Switch>
                {loading ?
                    <div className="loading active">
                        <div className="loading-container">
                            <img src="/logos/logo_pv.png" />
                        </div>
                    </div> : null
                }
                <Footer />
            </div>
        </div>
    );
}

export default App;

