
import React from 'react';

export const contactusValidation = (values) => {
    let errors = {};
    //name
    if (!values.name) {
        errors.name = "* 필수 입력 항목입니다.";
    }

    //email
    const regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-zZ]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

    if(!values.email) {
        errors.email = "* 필수 입력 항목입니다.";
    } else if(!regEmail.test(values.email)) {
       errors.email = "* 이메일 형식이 잘못 되었습니다.";
    }

    //phone
    if(!values.phoneNumber) {
        errors.phoneNumber = "* 필수 입력 항목입니다.";
    }

    //company
    if(!values.company) {
        errors.company = "* 필수 입력 항목입니다.";
    }

    return errors;
};
